<template>
  <div
    v-show="isShow"
    class="bsc-cart-item-ranklist-tag"
    @click="handleClick"
  >
    <div class="bsc-cart-item-ranklist-tag__left">
      <span class="bsc-cart-item-ranklist-tag__text">
        {{ text }}
      </span>
      <span class="bsc-cart-item-ranklist-tag__suffix-text">
        {{ suffixText }}
      </span>
    </div>

    <sui_icon_more_right_12px
      class="bsc-cart-item-ranklist-tag__icon"
      v-if="isClick"
      color="rgba(213, 135, 0, 1)"
    />
  </div>
</template>
<script name="RankListTag" lang="ts" setup>
import { watch } from 'vue'
import sui_icon_more_right_12px from '../../../components/icon/sui_icon_more_right_12px.vue'
import type { RankListTag } from '../../../../../types/mobile/index'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'

const props = withDefaults(defineProps<RankListTag.Props>(), {
  text: '',
  suffixText: '',
  bgColor: '',
  textColor: '',
  isShow: false,
  isShowMask: false,
  isClick: false,
  type: '',
  maxWidth: '',
})

const registerExpose = useInjectRegisterExpose()
const emit = defineEmits(['onClick', 'onReport', 'onExpose'])

function handleClick() {
  emit('onReport', props.data)
  if (props.isClick) {
    emit('onClick', props.data)
  }
}
function handleExpose() {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose', props.data)
      }
      registerExpose('RankListTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>
<style lang="less">
.bsc-cart-item-ranklist-tag {
  background-color: #FFFBEB;
  border-radius: 6px 2px 2px 1px;
  padding-right: 4px;
  color: #D58700;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  height: 20px;
  &__left {
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__text {
    display: inline-block;
    border-radius: 6px 1px;
    padding: 2px;
    background: #FFE8AE;
    font-weight: 700;
    color: #C77210;
    margin-right: 2px;
    line-height: 16px;
  }
  &__icon {
    flex-shrink: 0;
  }
}
</style>
