import type { RankListTag } from '../../../../../types/laptop/index'
import { getProps as getPromoProps } from '../promotion-tag/helper'

export function getProps({ data }):RankListTag.Props {
  let apb = data.value.aggregateProductBusiness || {}
  if(!apb?.productTags) apb = data.value // for share select api
  let info = apb.productTags?.find(item => item.type === 'rankList')
  const promoTagProps = getPromoProps({ data })
  return {
    isShow: !promoTagProps?.isShow && !!info,
    isShowMask: false,
    isClick: false, // PC端默认不可点击
    text: info?.view?.text || '',
    suffixText: info?.view.suffixText || '',
    type: info?.type,
    bgColor: '',
    textColor: '',
    maxWidth: '',
    data: info
  }
}