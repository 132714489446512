<template>
  <div
    ref="tempRef"
    class="bsc-cart-item-main__wrap"
  >
    <div class="bsc-cart-item-main__content">
      <!-- 主图、标签、腰带 -->
      <div class="bsc-cart-item-main__left">
        <GoodsImg
          v-if="goodsImgData.isShow"
          v-bind="goodsImgData.attrs"
        >
          <template #left>
            <GoodsDiscountFlag
              v-if="goodsDiscountFlagData.isShow"
              v-bind="goodsDiscountFlagData.attrs"
            />
          </template>
          <template #right>
            <GoodsSeriesFlag
              v-if="goodsSeriesFlagData.isShow"
              v-bind="goodsSeriesFlagData.attrs"
            />
          </template>
          <template #bottom>
            <GoodsBelt
              v-if="goodsBeltData.isShow"
              v-bind="goodsBeltData.attrs"
            />
          </template>
        </GoodsImg>
      </div>
      <div class="bsc-cart-item-main__right">
        <!-- 标题 -->
        <div class="bsc-cart-item-main__right-top">
          <GoodsTitle
            v-if="goodsTitleData.isShow"
            v-bind="goodsTitleData.attrs"
          >
            <template #left>
              <GoodsTitleIcon
                v-if="goodsTitleIconData.isShow"
                v-bind="goodsTitleIconData.attrs"
              />
            </template>
          </GoodsTitle>
          <GoodsStore
            v-if="goodsStoreData.isShow"
            class="bsc-cart-item-main__store"
            v-bind="goodsStoreData.attrs"
          />
        </div>
        <div class="bsc-cart-item-main__right-middle">
          <!-- 切 MALL、尺码、找同款、商品主图定制、用户行为标签 -->
          <div class="bsc-cart-item-main__info">
            <ChangeMall
              v-if="changeMallData.isShow"
              class="bsc-cart-item-main__change-mall"
              v-bind="changeMallData.attrs"
            />
            <GoodsSaleAttr
              v-if="goodsSaleAttrData.isShow"
              v-bind="goodsSaleAttrData.attrs"
            />
            <FindSimilarText
              v-if="findSimilarTextData.isShow"
              class="bsc-cart-item-main__find-similar-text"
              v-bind="findSimilarTextData.attrs"
            />
            <GoodsCustomContent
              v-if="goodsCustomContentData.isShow"
              v-bind="goodsCustomContentData.attrs"
            />
            <ClientOnly>
              <BehaviorLabel
                v-if="behaviorLabelData.isShow"
                class="bsc-cart-item-main__behavior-label"
                v-bind="behaviorLabelData.attrs"
              />
            </ClientOnly>
          </div>
          <!-- 活动标签 -->
          <div class="bsc-cart-item-main__promotion">
            <SFSTag
              v-if="sfsTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="sfsTagData.attrs"
            />
            <QuickShipTag
              v-if="quickShipTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="quickShipTagData.attrs"
            />
            <HolidayDeliveryTag
              v-if="holidayDeliveryTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="holidayDeliveryTagData.attrs"
            />
            <FreeShippingTag
              v-if="freeShippingTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="freeShippingTagData.attrs"
            />
            <EvoluTag
              v-if="evoluTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="evoluTagData.attrs"
            />
            <VoucherTag
              v-if="voucherTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="voucherTagData.attrs"
            />
            <PromotionTag
              v-if="promotionTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="promotionTagData.attrs"
            />
            <MorePromotionTag
              v-if="morePromotionTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="morePromotionTagData.attrs"
            />
            <RankListTag
              v-if="rankListTagData.isShow"
              class="bsc-cart-item-main__promotion-item"
              v-bind="rankListTagData.attrs"
            />
          </div>
          <!-- 提示信息 -->
          <div class="bsc-cart-item-main__tips">
            <GoodsAlert
              v-if="goodsAlertData.isShow"
              class="bsc-cart-item-main__alert"
              v-bind="goodsAlertData.attrs"
            />
            <ReducePriceHint
              v-if="reducePriceHintData.isShow"
              class="bsc-cart-item-main__reduce"
              v-bind="reducePriceHintData.attrs"
            />
          </div>
        </div>
        <!-- 价格、数量、删除、找同款、收藏-->
        <div class="bsc-cart-item-main__right-bottom">
          <div class="bsc-cart-item-main__prices">
            <GoodsPriceV1
              v-if="goodsPriceData.isShow"
              class="bsc-cart-item-main__price"
              v-bind="goodsPriceData.attrs"
            >
              <template #discount-tag>
                <DiscountNormalTag
                  v-if="discountNormalTagData.isShow"
                  v-bind="discountNormalTagData.attrs"
                />
                <DiscountLimitTag
                  v-if="discountLimitTagData.isShow"
                  v-bind="discountLimitTagData.attrs"
                />
              </template>
            </GoodsPriceV1>
            <EstimatedPrice
              v-if="estimatedPriceData.isShow"
              v-bind="estimatedPriceData.attrs"
            />
          </div>
          <div class="bsc-cart-item-main__operation">
            <GoodsQty
              v-if="goodsQtyData.isShow"
              v-bind="goodsQtyData.attrs"
            />
            <GoodsRepickButton
              v-if="goodsRepickButtonData.isShow"
              class="bsc-cart-item-main__repick-button"
              v-bind="goodsRepickButtonData.attrs"
            />
            <FindSimilarIcon
              v-if="findSimilarIconData.isShow"
              class="bsc-cart-item-main__find-similar"
              v-bind="findSimilarIconData.attrs"
            />
            <GoodsAddWish
              v-if="goodsAddWishData.isShow"
              class="bsc-cart-item-main__add-wish"
              v-bind="goodsAddWishData.attrs"
            />
            <GoodsDeleteButtonV1
              v-if="goodsDeleteButtonData.isShow && goodsDeleteButtonData.type === 'v1'"
              class="bsc-cart-item-main__delete"
              v-bind="goodsDeleteButtonData.attrs"
            />
            <div
              v-if="goodsDeleteButtonData.isShow && goodsDeleteButtonData.type === 'v2'"
              class="bsc-cart-item-main__delete"
            >
              <!-- SPopover 不接受 scoped 属性 -->
              <GoodsDeleteButtonV2
                v-bind="goodsDeleteButtonData.attrs"
              />
            </div>
            <GoodsQtyText
              v-if="goodsQtyTextData.isShow"
              class="bsc-cart-item-main__qty-text"
              v-bind="goodsQtyTextData.attrs"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 放置一些通栏的信息 -->
    <div
      v-if="goodsNegativeInfoData.isShow"
      class="bsc-cart-item-main__other"
    >
      <!-- 负向信息 -->
      <GoodsNegativeInfo
        v-if="goodsNegativeInfoData.isShow"
        class="bsc-cart-item-main__negative"
        v-bind="goodsNegativeInfoData.attrs"
      />
    </div>
  </div>
</template>

<script name="BMainCartItem" setup lang="ts">
import type { Laptop } from '../../../types/laptop'
import { onMountedOrActivated } from '../hooks/onMountedOrActivated'

import { ClientOnly } from '@sheinfe/vue-client-only'

// cell components
import { GoodsImg, useGoodsImg } from '../cell/goods-img'
import { GoodsTitle, useGoodsTitle } from '../cell/goods-title'
import { GoodsStore, useGoodsStore } from '../cell/goods-store'
import { GoodsSeriesFlag, useGoodsSeriesFlag } from '../cell/goods-series-flag'
import { GoodsDeleteButtonV1, GoodsDeleteButtonV2, useGoodsDeleteButton } from '../cell/goods-delete-button'
import { GoodsDiscountFlag, useGoodsDiscountFlag } from '../cell/goods-discount-flag'
import { GoodsCustomContent, useGoodsCustomContent } from '../cell/goods-custom-content'
import { GoodsSaleAttr, useGoodsSaleAttr } from '../cell/goods-sale-attr'
import { GoodsAddWish, useGoodsAddWish } from '../cell/goods-add-wish'
import { GoodsAlert, useGoodsAlert } from '../cell/goods-alert'
import { BehaviorLabel, useBehaviorLabel } from '../cell/behavior-label'
import { FindSimilarText, useFindSimilarText } from '../cell/find-similar-text'
import { FindSimilarIcon, useFindSimilarIcon } from '../cell/find-similar-icon'
import { MorePromotionTag, useMorePromotionTag } from '../cell/goods-tags/more-promotion-tag'
import { QuickShipTag, useQuickShipTag } from '../cell/goods-tags/quick-ship-tag'
import { FreeShippingTag, useFreeShippingTag } from '../cell/goods-tags/free-shipping-tag'
import { EvoluTag, useEvoluTag } from '../cell/goods-tags/evolu-tag'
import { VoucherTag, useVoucherTag } from '../cell/goods-tags/voucher-tag'
import { PromotionTag, usePromotionTag } from '../cell/goods-tags/promotion-tag'
import { SFSTag, useSFSTag } from '../cell/goods-tags/sfs-tag'
import { ReducePriceHint, useReducePriceHint } from '../cell/reduce-price-hint'
import { GoodsQty, useGoodsGoodsQty } from '../cell/goods-qty'
import { GoodsBelt, useGoodsBelt } from '../cell/goods-belt'
import { GoodsPriceV1, useGoodsPrice } from '../cell/goods-price'
import { EstimatedPrice, useEstimatedPrice } from '../cell/estimated-price'
import { DiscountNormalTag, useDiscountNormalTag } from '../cell/discount-normal-tag'
import { DiscountLimitTag, useDiscountLimitTag } from '../cell/discount-limit-tag'
import { GoodsTitleIcon, useGoodsTitleIcon } from '../cell/goods-title-icon'
import { ChangeMall, useChangeMall } from '../cell/change-mall'
import { GoodsRepickButton, useGoodsRepickButton } from '../cell/goods-repick-button'
import { GoodsNegativeInfo, useGoodsNegativeInfo } from '../cell/goods-negative-info'
import { GoodsQtyText, useGoodsQtyText } from '../cell/goods-qty-text'
import { HolidayDeliveryTag, useHolidayDeliveryTag } from '../cell/goods-tags/holiday-delivery-tag'
import { RankListTag, useRankListTag } from '../cell/goods-tags/rank-list-tag'

/**
 * 主车商品行模板
 */

// template hooks
import { useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps<{
  data?: Laptop.Data
  config?: Laptop.Config<Laptop.MainCart>
  language: Laptop.Language<Laptop.MainCart>,
  constant: Laptop.Constant<Laptop.MainCart>,
  use: Laptop.MainCart[],
}>()

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsTitleData,
    goodsStoreData,
    goodsSeriesFlagData,
    goodsDeleteButtonData,
    goodsDiscountFlagData,
    goodsCustomContentData,
    goodsSaleAttrData,
    goodsAddWishData,
    goodsAlertData,
    behaviorLabelData,
    findSimilarTextData,
    findSimilarIconData,
    morePromotionTagData,
    quickShipTagData,
    freeShippingTagData,
    evoluTagData,
    voucherTagData,
    promotionTagData,
    reducePriceHintData,
    goodsQtyData,
    goodsBeltData,
    estimatedPriceData,
    discountNormalTagData,
    discountLimitTagData,
    goodsPriceData,
    goodsTitleIconData,
    changeMallData,
    goodsRepickButtonData,
    goodsNegativeInfoData,
    sfsTagData,
    goodsQtyTextData,
    holidayDeliveryTagData,
    rankListTagData,
  ],

  // methods
  registerExpose,
} = useTemplate(
  props,
  // Helper functions used by the current template
  [
    useGoodsImg,
    useGoodsTitle,
    useGoodsStore,
    useGoodsSeriesFlag,
    useGoodsDeleteButton,
    useGoodsDiscountFlag,
    useGoodsCustomContent,
    useGoodsSaleAttr,
    useGoodsAddWish,
    useGoodsAlert,
    useBehaviorLabel,
    useFindSimilarText,
    useFindSimilarIcon,
    useMorePromotionTag,
    useQuickShipTag,
    useFreeShippingTag,
    useEvoluTag,
    useVoucherTag,
    usePromotionTag,
    useReducePriceHint,
    useGoodsGoodsQty,
    useGoodsBelt,
    useEstimatedPrice,
    useDiscountNormalTag,
    useDiscountLimitTag,
    useGoodsPrice,
    useGoodsTitleIcon,
    useChangeMall,
    useGoodsRepickButton,
    useGoodsNegativeInfo,
    useSFSTag,
    useGoodsQtyText,
    useHolidayDeliveryTag,
    useRankListTag,
  ],
)

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
// 模板左侧宽度
@template-left-width: 120px;
// 模板左侧高度
@template-left-height: 120px;
// 模板左右间距
@template-gap: 16px;
// 模板右侧元素上下间距
@template-right-gap: 8px;
// 模板背景色
@template-background-color: #fff;

.bsc-cart-item-main {
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: var(--template-background-color, @template-background-color);
    text-align: left;
    gap: @template-right-gap;
    line-height: 1.15;
  }
  &__content {
    display: flex;
  }
  &__left {
    margin-right: var(--template-gap, @template-gap);
    width: var(--template-left-width, @template-left-width);
    height: var(--template-left-height, @template-left-height);
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: var(--template-left-height, @template-left-height);
    width: 0;
    gap: @template-right-gap;
  }
  &__right-top {
    display: flex;
    flex-direction: column;
  }
  &__right-middle {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: @template-right-gap;
  }
  &__right-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__other {
    &:empty {
      margin-top: -@template-right-gap;
    }
  }
  &__store {
    margin-top: 8px;
  }
  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: @template-right-gap;
    // 为空时消除父元素的 gap 间距
    &:empty {
      margin-top: -@template-right-gap;
    }
  }
  &__promotion {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    // 为空时消除父元素的 gap 间距
    &:empty {
      margin-top: -@template-right-gap;
    }
  }
  &__tips {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: @template-right-gap;
    &:empty {
      margin-top: -@template-right-gap;
    }
  }
  &__prices {
    flex: 1;
    overflow: hidden;
    margin-right: 20px;
  }
  &__price {
    margin-right: 8px;
  }
  &__operation {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  &__repick-button {
    margin-left: 12px;
  }
  &__find-similar {
    margin-left: 12px;
  }
  &__add-wish {
    margin-left: 12px;
  }
  &__delete {
    margin-left: 12px;
  }
  &__negative {
    margin-left: calc(var(--template-left-width, @template-left-width) + @template-gap);
  }
}
</style>
